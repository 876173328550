import Cookies from 'js-cookie'
import store from '@/store'

/**
 * 权限
 * @param {*} key
 */
export function hasPermission (key) {
  return window.SITE_CONFIG.permissions.indexOf(key) !== -1 || false
}

/**
 * 获取字典数据列表
 * @param dictType  字典类型
 */
export function getDictDataList (dictType) {
  const type = window.SITE_CONFIG.dictList.find((element) => (element.dictType === dictType))
  if (type) {
    return type.dataList
  } else {
    return []
  }
}

/**
 * 获取字典名称
 * @param dictType  字典类型
 * @param dictValue  字典值
 */
export function getDictLabel (dictType, dictValue) {
  const type = window.SITE_CONFIG.dictList.find((element) => (element.dictType === dictType))
  if (type) {
    const val = type.dataList.find((element) => (element.dictValue === dictValue + ''))
    if (val) {
      return val.dictLabel
    } else {
      return dictValue
    }
  } else {
    return dictValue
  }
}

/**
 * 清除登录信息
 */
export function clearLoginInfo () {
  store.commit('resetStore')
  Cookies.remove('token')
  window.SITE_CONFIG.dynamicMenuRoutesHasAdded = false
}

/**
 * 获取uuid
 */
export function getUUID () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
  })
}

/**
 * 获取svg图标(id)列表
 */
export function getIconList () {
  var res = []
  var list = document.querySelectorAll('svg symbol')
  for (var i = 0; i < list.length; i++) {
    res.push(list[i].id)
  }

  return res
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate (data, id = 'id', pid = 'pid') {
  var res = []
  var temp = {}
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
    if (!temp[data[k][pid]] || data[k][id] === data[k][pid]) {
      res.push(data[k])
      continue
    }
    if (!temp[data[k][pid]].children) {
      temp[data[k][pid]].children = []
    }
    temp[data[k][pid]].children.push(data[k])
    data[k]._level = (temp[data[k][pid]]._level || 0) + 1
  }
  return res
}

/**
 * 压缩图片质量
 * @param file
 * @returns {Promise<any>|*}
 */
export function imgResize (file) {
  // 图片大于1m进行压缩
  const isLt1M = file.size / 1024 / 1024 < 1
  if (!isLt1M) {
    const maxWidth = 1920
    return new Promise(resolve => {
      const reader = new FileReader()
      const image = new Image()
      image.onload = () => {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        const quality = image.width > maxWidth ? Math.floor(maxWidth / image.width * 100) / 100 : 0.9
        const width = image.width * quality
        const height = image.height * quality
        canvas.width = width
        canvas.height = height
        context.clearRect(0, 0, width, height)
        context.drawImage(image, 0, 0, width, height)
        const dataUrl = canvas.toDataURL(file.type, 0.9)
        const blobData = dataURItoBlob(dataUrl, file.type)
        resolve(blobData)
      }
      reader.onload = e => { image.src = e.target.result }
      reader.readAsDataURL(file)
    })
  }
  return file
}
export function dataURItoBlob (dataURI, type) {
  const binary = atob(dataURI.split(',')[1])
  const array = []
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], { type: type })
}
