import http from "@/utils/request"

//获取老人信息
export function getUserinfo(data){
    return http({
        url:'/oldPeople/information',
        method:'post',
        data
    })
}


//查询护理动作  
export function nursing(){
    return http({
        url:'/dicNursingActions/action',
        method:'post',
        
    })
}

//查询护理时间线  
export function timeLine(data){
    return http({
        url:'/timeSlots/timeline',
        method:'post',
        data
    })
}

//生成计划
export function adjust(data){
    return http({
        url:'/elderDemand/adjust',
        method:'post',
        data
    })
}

