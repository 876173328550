<template>
  <div class="about">
    <div class="bodyblock">
      <div class="header">
        <h1>智能照护平台</h1>
        <div class="zhiliang">
          <router-link to="/rcmpage">实时养老服务质量</router-link>
        </div>
      </div>
      <div class="searchblock">
        <div>
          <img src="../assets/about/search.png" alt="" />
        </div>
        <div class="search">
          <el-select
            v-model="username"
            @clear="clearName"
            clearable
            @change="changeName"
            filterable
            remote
            :remote-method="remoteMethod"
            placeholder="请输入搜索"
          >
            <el-option
              v-for="item in seachList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="mian">
        <div class="leftblock">
          <el-dialog
            :title="username"
            :visible.sync="chiocepopup"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
          >
            <el-form>
              <el-form-item label="护理员">
                <el-select
                  v-model="huliname"
                  filterable
                  remote
                  :remote-method="hulinameMethod"
                  @clear="clearhuliName"
                  clearable
                  placeholder="请选择要修改的护理员"
                >
                  <el-option
                    v-for="item in nurser"
                    :key="item.id"
                    :label="item.attendantName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="时间段">
                <el-time-select
                  v-model="timevalue"
                  :picker-options="{
                    start: '01:00',
                    step: '00:30',
                    end: '24:00',
                  }"
                  @change="changetime"
                  placeholder="请选择护理时间段"
                >
                </el-time-select>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="CancelPop">取 消</el-button>
              <el-button type="primary" @click="ConfirmPop">确 定</el-button>
            </div>
          </el-dialog>
          <div
            class="userinfo"
            v-for="(item, index) in seachUser"
            :key="index"
            @click="findtimeLine(item.id)"
          >
            <div class="userbody" style="overflow: hidden">
              <div class="usertitle">{{ item.name }}（{{ item.age }}岁）</div>
              <div class="line">
                <draggable
                  class="drag-wrapper top3_drag-wrapper"
                  :animation="300"
                  forceFallback="true"
                  @start="drag = false"
                  filter=".forbid"
                  :group="{
                    name: 'list',
                    pull: false,
                    put: true,
                  }"
                  :force-fallback="true"
                  @add="personadd(item.id, $event)"
                  v-model="hulitimeline"
                  :move="checkMove"
                >
                  <!-- <div
                    v-for="(activity, index) in item.hulitimeline"
                    :key="index"
                  >
                    时间{{ activity.TimeSlot }}<br />
                    {{ activity.Activity }}
                    {{ activity.name }}
                  </div> -->

                  <el-timeline>
                    <el-timeline-item
                      v-for="(activity, index) in item.hulitimeline"
                      :key="index"
                      :timestamp="activity.timeSlot"
                    >
                      {{ activity.activity }}
                    </el-timeline-item>
                  </el-timeline>
                </draggable>
              </div>
            </div>
            <!-- //改 -->
            <div class="tooltip">{{ item.note }}</div>
          </div>
        </div>
        <div class="centerblock">
          <div class="setandhuli">
            <div class="huli">
              <div class="hulititle">{{ nursingAction.name }}</div>
              <div class="block">
                <el-cascader
                  placeholder="请选择/搜索护理动作"
                  :options="childrenList"
                  :props="{
                    value: 'id',
                    label: 'name',
                  }"
                  filterable
                  @change="huliaction"
                ></el-cascader>
              </div>
              <draggable
                v-model="myArray"
                chosenClass="chosen"
                forceFallback="true"
                :group="{
                  name: 'list',
                  pull: 'clone',
                  put: false,
                }"
                animation="1000"
                v-if="actiontrue"
                class="actionitem"
              >
                <transition-group>
                  <div
                    class="item"
                    v-for="element in myArray"
                    :key="element.id"
                    style="margin-top: 0.5vw"
                  >
                    {{ element.name }}
                  </div>
                </transition-group>
              </draggable>
            </div>
          </div>
          <div class="cenicon" v-if="setopen">
            <img src="../assets/about/fanhui.png" alt="" @click="rightClose" />
          </div>
          <div class="setblock" v-else>
            <div class="set">
              <div>
                <el-button round @click="clickbaocun">生成计划</el-button>
              </div>
            </div>
            <div class="closeicon">
              <img src="../assets/about/close.png" alt="" @click="rightClose" />
            </div>
          </div>
        </div>
        <div class="elderInfo" v-for="item in showUser" :key="item.id">
          <div>{{ item.name }}（{{ item.age }}岁）</div>
        </div>
        <div style="width: 100%" v-if="timelineList !== null">
          <horizontal-timeline
            class="horizontal-timeline"
            :timelineList="timelineList"
            :leftArrowShow="true"
            :rightArrowShow="true"
            :mousewheelDisable="true"
            :showScroll="false"
            :positionId="20"
            @right-loading="right"
            @left-loading="left"
          >
            <template v-slot:default="slotProps">
              <div class="content">
                <div class="chat">
                  <div v-if="slotProps.item.activity !== ''">
                    {{ slotProps.item.activity }}
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:time="slotProps">
              <div class="time">
                {{ slotProps.item.timeSlot }}
                <div
                  style="margin-top: 5px"
                  v-if="slotProps.item.caregiver != 'None'"
                >
                  护理员：{{ slotProps.item.caregiver }}
                </div>
              </div>
            </template>
          </horizontal-timeline>
        </div>
        <div
          style="
            height: 250px;
            font-size: 30px;
            margin: 0 auto;
            line-height: 250px;
            color: #00ffff;
          "
          v-if="timelineList.length == 0"
        >
          暂无数据
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import draggable from "vuedraggable";
// import * as echarts from "echarts";
import { getUserinfo, nursing, timeLine, adjust } from "@/api/user";
import { gethuliyuan } from "@/api/huli";
import HorizontalTimeline from "@/components/vue-horizontal-timeline-component/src/vue-horizontal-timeline-component.vue";
export default {
  components: { draggable, HorizontalTimeline },
  data() {
    return {
      timelineList: [],
      drag: false,
      //定义要被拖拽对象的数组
      myArray: [],
      username: "", //搜索框
      searchicon: true,
      setopen: false,
      setclose: false,
      myAction: [], //护理动作
      actiontrue: false, //护理动作的显示
      seachUser: [], //搜索信息
      seachList: {},
      nursingAction: [], //护理动作
      hulitimeline: [], //护理时间线
      TimeSlotarray: [], //曲线图展示的时间段
      childrenList: [],
      timevalue: "", //选择的时间段
      huliname: "", //选中的护理员
      chiocetimes: "", //展示的时间段
      chiocepopup: false, //弹窗
      list2: [],
      demoshow: false, //曲线图的显示
      // 查询护理员
      nurser: null,
      // 拖拽护理动作
      Nustime: "",
      user: null,
      showUser: [],
      chartsData: {
        xAxis: {
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        },
        yAxis: {
          type: "time",
          min: "2024-08-03 00:00",
          max: "2024-08-03 23:59",
          scale: false,
          splitNumber: 10,
          axisLabel: {
            color: "#FFF",
          },
        },
        series: [
          {
            data: [
              "2024-08-03 04:00",
              "2024-08-03 11:00",
              "2024-08-03 15:00",
              "2024-08-03 17:00",
              "2024-08-03 13:00",
              "2024-08-03 12:00",
            ],
            type: "line",
            label: {
              show: true,
              position: "top",
              formatter: function (params) {
                return params.value.split(" ")[1]; // 显示y值
              },
              color: "#FFF",
            },
          },
        ],
      },
      list: [],
      events: [
        { title: "事件 1", description: "描述 1", date: "2023-01-01" },
        { title: "事件 2", description: "描述 2", date: "2023-02-01" },
        { title: "事件 3", description: "描述 3", date: "2023-03-01" },
        { title: "事件 4", description: "描述 4", date: "2023-04-01" },
        { title: "事件 5", description: "描述 5", date: "2023-05-01" },
      ],
      activities: [
        {
          content: "Custom icon",
          timestamp: "2018-04-12 20:46",
          size: "large",
          type: "primary",
          // icon: MoreFilled,
        },
        {
          content: "Custom color",
          timestamp: "2018-04-03 20:46",
          color: "#0bbd87",
        },
        {
          content: "Custom size",
          timestamp: "2018-04-03 20:46",
          size: "large",
        },
        {
          content: "Custom hollow",
          timestamp: "2018-04-03 20:46",
          type: "primary",
          hollow: true,
        },
        {
          content: "Default node",
          timestamp: "2018-04-03 20:46",
        },
      ],
      items: [],
      rightComplete: false,
      leftComplete: false,
      firstSelection: true,
    };
  },
  mounted() {
    let { id } = this.$route.query;
    if (id) {
      this.changeName(id);
     
    } else {
      this.userInfo();
      // this.drawChart();
      // this.findtimeLine();
      // this.findnursing();
      // this.gethuliyuans();
    }
     this.findnursing();
      this.gethuliyuans();
    document.onselectstart = function () {
      return false;
    };
  },
  computed: {},
  methods: {
    clickrcm() {
      this.$router.push({ url: "/rcmpage" });
    },
    rightClose() {
      this.setopen = !this.setopen;
    },
    changetime(e) {
      let timevalue = moment(e, "HH:mm").add(30, "minute").format("HH:mm");
      if (this.timevalue == "23:30") {
        timevalue = "24:00";
      }
      if (this.timevalue == "24:00") {
        this.timevalue = "00:00";
      }
      this.chiocetimes = `${this.timevalue}-${timevalue}`;
    },
    handleClose() {},
    // 老人信息请求
    userInfo() {
      getUserinfo({
        id: null,
        name: null,
      }).then((res) => {
        if (res.data.data) {
          let seachUser = res.data.data;
          // 初始化搜索列表，只包含第一个用户
          seachUser.forEach((item, index) => {
            timeLine({ id: item.id }).then((res) => {
              let line = res.data.data;
              item.hulitimeline = line ? line : [];
              seachUser.splice(index, 1, item);
              if (index == 0) {
                this.timelineList = line;
              }
              // item.hulitimeline.forEach((element) => {
              //   // 只有第一个用户的时间线元素才会被推送到时间线列表中
              //   if (index == 0) {
              //     this.timelineList.push(element);
              //   }
              // });
            });
          });
          this.seachUser = seachUser;
          // this.seachList = [this.seachUser[0]];
          this.showUser = [this.seachUser[0]];
          console.log(this.seachUser, "kkkkkkkkkkkk");
          // this.findtimeLine(this.seachList.id);
        }
      });
    },
    //查询护理动作
    findnursing() {
      let getChildren = (arr) => {
        arr.forEach((item) => {
          if (item.level == 3 || item.children.length < 1) {
            delete item.children;
          } else if (item.children && item.children.length > 0) {
            getChildren(item.children);
          }
        });
      };
      nursing().then((res) => {
        if (res.data.data) {
          this.nursingAction = res.data.data;
          let fChildren = JSON.parse(JSON.stringify(res.data.data.children));
          getChildren(fChildren);
          this.childrenList = fChildren;
        }
      });
    },
    expandChange(e) {
      console.log(e, "000");
    },
    //查询老人护理时间线
    findtimeLine(id) {
      this.timelineList = [];
      this.seachUser.forEach((item) => {
        if (id == item.id) {
          this.showUser = [item];
          this.timelineList = item.hulitimeline;
        }
      });
    },
    // 模糊搜索老人名字
    changeName(e) {
      getUserinfo({
        id: e,
      }).then((res) => {
        if (res.data.data) {
          let seachUser = res.data.data;
          seachUser.forEach((item, index) => {
            timeLine({ id: item.id }).then((res) => {
              let line = res.data.data;
              item.hulitimeline = line ? line : [];
              seachUser.splice(index, 1, item);
              if (index == 0) {
                this.timelineList = line;
              }
              console.log(line);
            });
          });
          this.seachUser = seachUser;
        }
        // console.log(this.seachUser)
        this.showUser = this.seachUser;
        // this.seachUser.forEach((item) => {
        //   if (e == item.id) {
        //     this.showUser = [item];
        //     this.timelineList = item.hulitimeline
        //     // item.hulitimeline.forEach((element) => {
        //     //   this.timelineList.push(element);
        //     // });
        //   }
        // });
      });
    },
    // 老人模糊搜索
    remoteMethod(e) {
      console.log(e, "老人模糊搜索");
      if (e) {
        getUserinfo({
          id: "",
          name: e,
        }).then((res) => {
          if (res.data.data) {
            this.seachList = res.data.data;
          }
        });
      } else {
        this.userInfo();
      }
    },

    //搜索框清空
    clearName() {
      this.seachList = this.seachUser;
      this.username = "";
      this.userInfo();
    },

    //开始拖拽事件
    onStart() {
      this.drag = true;
    },
    //拖拽结束事件
    onEnd() {
      this.drag = false;
    },
    // 查询护理员
    gethuliyuans() {
      gethuliyuan().then((res) => {
        this.nurser = res.data.data;
      });
    },
    // 选择护理动作
    huliaction(e) {
      let leg = e.length;
      let id = leg > 1 ? e[leg - 1] : e[0];
      let that = this;
      (function getChildrenList(arr) {
        arr.forEach((item) => {
          if (item.id == id) {
            if (item.children.length < 1) {
              that.myArray = [item];
            } else {
              that.myArray = item.children;
            }
          } else if (item.children && item.children.length > 0) {
            getChildrenList(item.children);
          }
        });
      })(this.nursingAction.children);
      if (e) {
        this.actiontrue = true;
      }
    },
    //护理员模糊搜索
    hulinameMethod() {},
    // 清空护理员搜索
    clearhuliName() {
      this.huliname = "";
    },
    // 拖拽护理动作
    personadd(id, e) {
      this.Nustime = this.myArray[e.oldIndex];
      let user = this.seachUser.find((item) => item.id == id);
      timeLine({ id: user.id }).then((res) => {
        this.list = res.data.data;
      });
      if (!this.user || user.id == this.user.id) {
        this.user = user;
        this.chiocepopup = true;
      } else {
        this.$notify.error({
          title: "错误",
          message: "该操作不是同一人，请重新拖拽",
        });
        6;
      }
      this.username = user.name;
    },
    persononStart(e) {
      console.log(e, "persononStart");
    },
    personend(e) {
      let id = this.seachUser[e.newIndex].id;
      console.log(id, "eeeeeeeeeeeeeeeee");
    },
    checkMove(evt) {
      console.log(evt);
      return true;
    },
    // 点击弹窗确认
    ConfirmPop() {
      if (!this.huliname && !this.chiocetimes) {
        this.$message({
          message: "请输入正确信息",
          type: "warning",
        });
      }
      this.list.forEach((item) => {
        if (item.timeSlot == this.chiocetimes) {
          item.activityId = this.Nustime.id;
          item.activity = this.Nustime.name;
          item.caregiverId = this.huliname;
          this.list2.push(item);
        }
      });
      if (this.firstSelection) {
        this.firstSelection = false; // 标记为已选择
      }
      this.chiocepopup = false;
      this.timevalue = "";
      this.huliname = "";
    },
    CancelPop() {
      if (this.firstSelection) {
        this.user = null; // 仅清空临时数据
      }
      this.chiocepopup = false;
      this.timevalue = "";
      this.huliname = "";
    },
    //生成计划
    clickbaocun() {
      adjust(this.list2).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            title: "成功",
            message: "生成计划成功，点击信息查看",
            type: "success",
          });
          this.userInfo();
          this.list = [];
          this.list2 = [];
          this.user = null;
        } else {
          this.$notify.error({
            title: "错误",
            message: res.message,
          });
          this.list = [];
          this.list2 = [];
        }
      });
    },
    // 时间线方法
    right(resolve) {
      resolve();
      this.rightComplete = true;
    },
    left(resolve) {
      resolve();
      this.leftComplete = true;
    },
  },
};
</script>

<style scoped lang='less'>
/deep/ .el-select .el-input.is-focus .el-input__inner {
  border-color: #074e56 !important;
}

/deep/ .el-select-dropdown {
  background-color: #21585e !important;
}

/deep/ .el-select-dropdown__item {
  color: white !important;
}

.huli {
  /deep/ .el-input__inner {
    background-color: #0a313a !important;
    border-radius: 20px;
    border: 1px solid #039dad;
    color: #ffffff;
  }
}

.search {
  /deep/ .el-input__inner {
    background-color: #0a313a !important;
    border-radius: 20px;
    border: 1px solid #039dad;
    color: aqua;
  }
}

/deep/.el-timeline-item__content {
  color: #00ffff;
}

/deep/.el-timeline-item__timestamp {
  color: #d5d6d9;
}

/deep/.el-timeline-item__node {
  background-color: #00ffff;
}

.history {
  .el-button {
    background: #0d3a45;
    border: 1px solid #07ffff;
    color: #00ffff;
  }
}

/deep/.el-dialog {
  background-image: url(../assets/about/huliimg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #054f4e;
  // width: 27vw;
  // height: 35vh;
  width: 573px;
  height: 350px;
  // z-index: 9999;
}

/deep/.el-form-item__label {
  color: white;
}

/deep/.el-dialog__title {
  color: white;
}

.userinfo {
  width: 30%;

  // background-color: red;
  position: relative;
  transform: rotate(-45deg);
  animation: heartbeat 1s infinite;
}

.userinfo:hover + .elementB {
  display: block;
}
.userinfo::before,
.userinfo::after {
  content: "";
  width: 30%;
  position: absolute;
}

.userinfo::before {
  top: -50px;
  left: 0;
}

.userinfo::after {
  left: 50px;
  top: 0;
}

.el-timeline {
  height: 20vh;
  overflow: scroll;
  scrollbar-width: none;
  padding: 6vh 1vh 2vh 2vh;
}

@keyframes heartbeat {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transform: scale(1);
  }

  10% {
    transform: scale(1.03);
  }

  30% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.03);
  }

  70% {
    transform: scale(1);
  }
}
</style>
<style lang="less" scoped>
.about {
  width: 100%;
  background: url(../assets/about/aboutpng.jpg) no-repeat;
  background-size: 100% 100%;

  .bodyblock {
    width: 96%;
    margin: 0 auto;

    .header {
      height: 15vh;
      background: url(../assets/about/header.gif) no-repeat;
      background-size: 100% 100%;

      h1 {
        color: white;
        line-height: 7vh;
      }
    }

    .searchblock {
      width: 30%;
      height: 5vh;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .mian {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .leftblock {
      width: 45%;
      height: 50vh;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;

      .huli_time {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        align-content: flex-end;
        height: 8vh;
        color: white;

        .chiocetime {
          font-size: 0.8em;
          color: gray;
          height: 4vh;
          line-height: 4vh;
          width: 100%;
        }
      }

      .usertitle {
        height: 4vh;
        width: 92%;
        line-height: 4vh;
        color: white;
        background: url(../assets/about/solit2.png) no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 3%;
        left: 6%;
      }

      .userinfo {
        width: 22%;
        position: relative;
        margin: 2vh 0;

        .drag-wrapper {
          height: 25vh;
          width: 100%;
          background: url(../assets/about/leftblock.png) no-repeat;
          background-size: 100% 100%;
          overflow: scroll;
          scrollbar-width: none;

          .userbody {
            position: relative;

            .line {
              height: 28vh;
              overflow: auto;
              position: absolute;
              top: 5vh;
              scrollbar-width: none;
              padding-left: 1vw;
            }
          }
        }
      }
      .tooltip {
        display: none; /* 默认隐藏 */
        position: absolute; /* 绝对定位 */
        color: #fff; /* 字体颜色 */
        padding: 5px; /* 内边距 */
        border-radius: 4px; /* 圆角 */
        left: 50%; /* 居中对齐 */
        transform: translateX(-50%); /* 通过平移调整居中 */
        z-index: 100; /* 确保在最上层 */
        background: #e4e7ed45;
        width: 100%;
      }
      .userinfo:hover .tooltip {
        display: block; /* 鼠标悬停时显示 */
      }
    }
    .centerblock {
      width: 50%;
      height: 50vh;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      .setandhuli {
        width: 30%;
        margin-right: 10vw;

        // display: flex;
        // .online{
        //   width: 2vw;
        //   height: 1.5vh;
        //   img{
        //     width: 100%;
        //     height: 100%;
        //   }
        // }
        .huli {
          height: 50vh;
          width: 100%;
          background: url(../assets/about/left.png) no-repeat;
          background-size: 100% 100%;
          margin: 0 auto;

          .actionitem {
            height: 39vh;
            overflow: auto;
            scrollbar-width: none;
          }

          .item {
            padding: 0.5vw;
            background-color: #062029;
            border: solid 1px #4ed0dc;
            margin-bottom: 10px;
            cursor: move;
            width: 60%;
            margin: 0 auto;
            color: #0b9db1;
            cursor: pointer;
          }

          /*选中样式*/
          .chosen {
            border: solid 2px #3089dc !important;
          }

          .hulititle {
            color: #00ffff;
            font-size: 1.3em;
            text-align: center;
            width: 44%;
            height: 5vh;
            line-height: 5vh;
          }

          .block {
            width: 90%;
            margin: 0 auto;
          }
        }
      }

      .cenicon {
        height: 4vh;
        position: relative;
        top: 0;
        right: 0;

        img {
          height: 2.5vh;
        }
      }

      .setblock {
        position: relative;

        .set {
          background: #041d23;
          height: 100%;
          border-radius: 5%;
          padding: 1vw;
          box-sizing: border-box;

          /deep/.el-button.is-round {
            padding: 9px 50px;
            cursor: pointer;
          }

          /deep/ .el-button {
            background: #0d3a45;
            border: 1px solid #04ffe1;
            color: #00ffff;
          }

          div {
            padding-top: 2.5vw;
            cursor: pointer;
          }

          // .history {
          //   display: flex;
          // }
        }

        .closeicon {
          height: 4vh;
          position: absolute;
          top: 0;
          right: 0;

          img {
            height: 3vh;
          }
        }
      }
    }
  }
}

.horizontal-timeline {
  margin-top: 40px;
  margin-bottom: 30px;
  width: 100%;
}

.content {
  // opacity: 0.5;
  // padding-bottom: 10px;
  width: 120px;
  height: 90px;
  font-size: 16px;
  color: #00ffff;
  border-radius: 10px;
  border: 1px solid #00ffff;
  padding: 0 10px;
}

.chat {
  position: relative;
  min-width: 100px;
  max-width: 180px;
  word-wrap: break-word;
  padding-top: 12px;
}

.time {
  color: #fff;
  position: absolute;
  top: -25px;
}

.elderInfo {
  // position: relative;
  position: relative;
  top: 20px;
  width: 100%;
  margin: auto 0;
  color: #fff;
  font-size: 20px;
}
.zhiliang {
  background: #04626c;
  width: 150px;
  height: 30px;
  line-height: 30px;
  border-radius: 10px;
  float: right;
  margin-top: 20px;
  a {
    color: white;
    text-decoration: none;
  }
}
</style>
